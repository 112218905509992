import React, { useContext } from 'react';

import { Container, Content, TextField, Error, CountLength } from './styles';
import FormContext from '../FormContext';

// 065.526.718-22
const Message = () => {
  const { handleChangeInput, data, errors } = useContext(FormContext);

  return (
    <Container>
      <Content>
        {errors.message && <Error>{errors.message}</Error>}
        <TextField
          name="message"
          value={data.message}
          onChange={handleChangeInput}
        />

        <CountLength>
          Caracteres atuais {data.message.length}. O mínimo é 67.
        </CountLength>
      </Content>
    </Container>
  );
};

export default Message;
