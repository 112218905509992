import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const Content = styled.div``;

export const TextField = styled.textarea`
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #eee;
  resize: vertical;
  font-size: 16px;
  min-height: 150px;
`;

export const Error = styled.span`
  width: 100%;
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
  color: #ff3b30;
`;

export const CountLength = styled.span`
  font-size: 14px;
`;
