import styled, { css } from 'styled-components';
import devices from '~/styles/config/device';

export const Container = styled.div`
  background-color: #fafafa;

  @media ${devices.laptop} {
    padding: 60px;
  }

  @media ${devices.mobile} {
    padding: 15px;
  }
`;

export const Content = styled.div`
  padding: 60px;
  border-radius: 8px;
  width: 100%;
  max-width: 440px;
  background-color: ${({ theme }) => theme.formBackground};
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  .material-input {
    margin-bottom: 15px;
  }

  @media ${devices.laptop} {
    margin: 0 auto;
  }

  @media ${devices.mobile} {
    padding: 45px;
  }
`;

export const Controls = styled.div`
  padding-top: 15px;
  display: flex;
  width: 100%;

  ${({ step }) =>
    step > 0
      ? css`
          justify-content: space-between;
        `
      : css`
          justify-content: flex-end;
        `}
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 800;
  color: #1c1c1e;
`;

export const Subtitle = styled.div`
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #3a3a3c;
  margin-bottom: 30px;
`;
