import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '~/components/Button';
import api from '~/services/api';

import { Container, Content, Controls, Title, Subtitle } from './styles';
import Claimant from './Claimant';
import Renter from './Renter';
import Departament from './Departament';
import Message from './Message';
import { claimant, renter, departament, message } from './validation';
import FormContext from './FormContext';

const Store = () => {
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({
    renter_id: '',
    renter_type: 'f',
    renter_cpf: '',
    renter_cnpj: '',
    claimant_id: '',
    claimant_cpf: '',
    claimant_is_renter: false,
    message: '',
  });

  const [errors, setErrors] = useState({});

  const steps = [
    {
      id: 1,
      title: 'Requerente',
      subtitle: '',
      validation: claimant,
      component: <Claimant />,
    },
    {
      id: 2,
      title: 'Inquilino',
      subtitle: '',
      validation: renter,
      component: <Renter />,
    },
    {
      id: 3,
      title: 'Departamento',
      subtitle: '',
      validation: departament,
      component: <Departament />,
    },
    {
      id: 4,
      title: 'Mensagem',
      subtitle: '',
      validation: message,
      component: <Message />,
    },
  ];

  const validateForm = async () => {
    if (!steps[currentStep].validation) return true;

    setErrors({});

    const schema = steps[currentStep].validation;
    const result = await schema
      .validate(data, {
        abortEarly: false,
      })
      .catch(err => ({ error: true, ...err }));

    if (result.error) {
      const validationErrors = {};

      result.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });

      setErrors(validationErrors);
    }

    return !result.error;
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextStep = async () => {
    const result = await validateForm();

    if (result) setCurrentStep(currentStep + 1);
  };

  const handleChangeInput = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submit = async () => {
    try {
      const res = await api.post(`/insereSolicitacao.php`, {
        requerente: {
          codigo: data.claimant_id,
          nome: data.claimant_name,
          doc: data.claimant_cpf,
          celular: data.claimant_phone,
          email: data.claimant_email,
          empresa: data.claimant_company,
          cargo: data.claimant_function_company,
          contato: data.claimant_contact_company,
          fone_empresa: data.claimant_phone_company,
        },
        interessado: {
          codigo: data.renter_id,
        },
        inquilino: {
          empresa: data.renter_company,
          cargo: data.renter_function_company,
          contato: data.renter_contact_company,
          fone_empresa: data.renter_phone_company,
          req_lt: data.claimant_is_renter,
        },
        solicitacao: {
          departamento: data.departament,
          descricao: data.message,
        },
      });

      if (res.status === 200 && res.data.result) {
        history.push({
          pathname: '/protocolo',
          state: {
            protocol: res.data.protocolo,
          },
        });

        toast.success(
          'Sua socilitação foi enviada... Aguarde e entraremos em contato!'
        );
      } else {
        toast.error(
          'Desculpe, ocorreu um erro ao efetuar sua solicitação. Por favor tente novamente ou entre em contato conosco.'
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <FormContext.Provider
        value={{
          handleChangeInput,
          setData,
          setErrors,
          data,
          errors,
        }}
      >
        <Content>
          <Title>{steps[currentStep].title}</Title>
          <Subtitle>{steps[currentStep].subtitle}</Subtitle>
          {steps[currentStep].component}
          <Controls step={currentStep}>
            {currentStep > 0 && (
              <Button color="invert" onClick={() => previousStep()}>
                Voltar
              </Button>
            )}
            {currentStep < 3 && (
              <Button color="success" onClick={() => nextStep()}>
                Próximo
              </Button>
            )}
            {currentStep === 3 && (
              <Button color="success" onClick={() => submit()}>
                Finalizar
              </Button>
            )}
          </Controls>
        </Content>
      </FormContext.Provider>
    </Container>
  );
};

export default Store;
