import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import DefaultLayout from '~/pages/_layouts/Default';
import Header from '~/components/Header';

const RouteWrapper = ({ component: Component, history, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <DefaultLayout>
        <Header />
        <Component {...props} />
      </DefaultLayout>
    )}
  />
);

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(RouteWrapper);
