import styled, { css } from 'styled-components';
import devices from '~/styles/config/device';

export const Container = styled.div`
  background-color: #fafafa;

  @media ${devices.laptop} {
    padding: 60px;
  }

  @media ${devices.mobile} {
    padding: 30px 15px 15px 15px;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 800;
  color: #1c1c1e;
  margin-bottom: 30px;
`;

export const Content = styled.div`
  padding: 60px;
  border-radius: 8px;
  width: 100%;
  max-width: 440px;

  background-color: ${({ theme }) => theme.formBackground};
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  .material-input {
    margin-bottom: 15px;
  }

  margin: 0 auto;

  @media ${devices.mobile} {
    padding: 45px;
  }
`;

export const Controls = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Info = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #1c1c1e;
`;

export const Value = styled.span`
  font-size: 16px;

  ${({ color }) =>
    color
      ? css`
          color: ${color};
        `
      : css`
          color: #2c2c2e;
        `}
`;
