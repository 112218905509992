import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const Content = styled.div``;

export const Card = styled.label`
  cursor: pointer;

  > input {
    display: none;

    + div {
      font-size: 18px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #eee;
      padding: 30px;
      margin-bottom: 15px;
    }

    &:checked + div {
      font-weight: 500;
      color: #007aff;
      border: 1px solid #007aff;
      background-color: ${lighten(0.4, '#007AFF')};
    }
  }
`;

export const Error = styled.span`
  width: 100%;
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
  color: #ff3b30;
`;
