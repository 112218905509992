import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import yup from '~/lib/yup';
import Input from '~/components/Material/Input';
import Button from '~/components/Button';
import api from '~/services/api';

import {
  Container,
  Content,
  Title,
  Controls,
  Info,
  Label,
  Value,
} from './styles';

const View = () => {
  const { id } = useParams();

  const [data, setData] = useState({
    protocol: id || '',
    email: '',
  });
  const [request, setRequest] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {}, []);

  const handleSubmit = async () => {
    const schema = yup.object({
      protocol: yup
        .string()
        .required()
        .label('Protocolo'),
      email: yup
        .string()
        .email()
        .required()
        .label('E-mail'),
    });

    setErrors({});

    const result = await schema
      .validate(data, {
        abortEarly: false,
      })
      .catch(err => ({ error: true, ...err }));

    if (result.error) {
      const validationErrors = {};

      result.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });

      setErrors(validationErrors);
    } else {
      const res = await api.post(`/consultaProtocolo.php`, {
        codigo: data.protocol,
        email: data.email,
      });

      if (res.status === 200 && res.data.result) {
        const requestData = res.data.data;

        setRequest({
          status: requestData.S_Status,
          created: requestData.S_Data_Criacao,
          departament: requestData.S_Departamento,
          message: requestData.S_Descricao,
          response: requestData.S_Resposta,
          collaborator: requestData.Usuario_Resposta,
        });
      } else if (res.status === 200 && !res.data.result) {
        toast.error(res.data.message);
      } else {
        toast.error(
          'Desculpe, ocorreu um erro ao efetuar a consulta. Por favor tente novamente ou entre em contato conosco.'
        );
      }

      console.log(res);
    }
  };
  const handleChangeInput = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const statusRequest = {
    A: {
      label: 'Aberta',
      color: '#007AFF',
    },
    F: {
      label: 'Finalizada',
      color: '#35C759',
    },
    R: {
      label: 'Respondida',
      color: '#5856D6',
    },
    C: {
      label: 'Cancelada',
      color: '#FF3B30',
    },
  };

  return (
    <Container>
      <Content>
        {!request ? (
          <Title>Consultar solicitação</Title>
        ) : (
          <Title>Solicitação {data.protocol}</Title>
        )}
        {!request ? (
          <>
            <Input
              label="Protocolo"
              name="protocol"
              value={data.protocol}
              error={errors.protocol}
              onChange={handleChangeInput}
            />
            <Input
              label="E-mail"
              name="email"
              value={data.email}
              error={errors.email}
              onChange={handleChangeInput}
            />
            <Controls>
              <Button color="success" onClick={handleSubmit}>
                Consultar
              </Button>
            </Controls>
          </>
        ) : (
          <>
            <Info>
              <Label>Status: </Label>
              <Value color={statusRequest[request.status].color}>
                {statusRequest[request.status].label}
              </Value>
            </Info>
            <Info>
              <Label>Data de criação: </Label>
              <Value>
                {new Date(request.created).toLocaleString('pt-BR').slice(0, -3)}
              </Value>
            </Info>
            <Info>
              <Label>Departamento: </Label>
              <Value>{request.departament}</Value>
            </Info>
            {/* <Info>
              <Label>Sua mensagem: </Label>
              <Value>{request.message}</Value>
            </Info> */}

            <Info>
              <Label>Colaborador: </Label>
              <Value>
                {request.collaborator !== 'SISTEMA'
                  ? request.collaborator
                  : 'aguardando atendente'}
              </Value>
            </Info>

            {request.response && (
              <Info>
                <Label>Resposta: </Label>
                <Value>{request.response}</Value>
              </Info>
            )}
            <Controls>
              <Button color="invert-border" onClick={() => setRequest('')}>
                Nova consulta
              </Button>
            </Controls>
          </>
        )}
      </Content>
    </Container>
  );
};

export default View;
