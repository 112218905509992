import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import { Container, Title, Protocol, Message, Consult } from './styles';

const Feedback = () => {
  const location = useLocation();

  if (!location.state) return <Redirect to="/" />;

  return (
    <Container>
      <div>
        <Title>
          Anote seu protocolo <Protocol>{location.state.protocol}</Protocol>
        </Title>
        <Message>
          Com esse código em breve você poderá consultar o status da sua
          solicitação.{' '}
          <Consult to={`/consultar-solicitacao/${location.state.protocol}`}>
            Página de consulta.
          </Consult>
        </Message>
      </div>
    </Container>
  );
};

export default Feedback;
