import React, { useContext, useState, useCallback } from 'react';
import Switch from '~/components/Switch';
import Input from '~/components/Material/Input';
import InputMask from '~/components/Material/InputMask';
import api from '~/services/api';

import {
  Container,
  TypeClaimantControl,
  TypePersonControl,
  TypePerson,
  BoxInfo,
  Info,
  Label,
  Value,
} from './styles';
import FormContext from '../FormContext';

// 51.851.277/0001-69 - Rossi
// 213.719.698-09 - Rodrigo
// 147.851.788-33 - Alessadra
const Renter = () => {
  const { handleChangeInput, data, errors, setData, setErrors } = useContext(
    FormContext
  );
  const [isRenter, setIsRenter] = useState(data.claimant_is_renter);

  const handleChangeIsRenter = useCallback(
    async value => {
      setErrors({});

      setIsRenter(value);

      const resetRenter = {
        claimant_is_renter: false,
        renter_id: '',
        renter_cpf: '',
        renter_phone: '',
        renter_email: '',
      };

      if (value) {
        const result = await api.post(`/retornaInteressado.php`, {
          doc: data.claimant_cpf,
        });

        if (result.status === 200 && result.data.result) {
          const person = result.data.data;

          setData(d => ({
            ...d,
            claimant_is_renter: true,
            renter_id: person.Codigo,
            renter_type: 'f',
            renter_cpf: data.claimant_cpf,
            renter_name: person.Nome,
            renter_phone: person.Celular,
            renter_email: person.E_mail,
            renter_company: data.claimant_company,
            renter_function_company: data.claimant_function_company,
            renter_contact_company: data.claimant_contact_company,
            renter_phone_company: data.claimant_phone_company,
          }));
        } else if (!result.data.result) {
          setErrors({
            renter_cpf: 'O cpf informado não pertence a um inquilino',
          });
          setIsRenter(false);
          setData(d => ({
            ...d,
            ...resetRenter,
          }));
        }
      } else if (!value && data.renter_id) {
        setData(d => ({
          ...d,
          ...resetRenter,
        }));
      }
    },
    [data, setData, setErrors]
  );

  const handleChangeDoc = useCallback(
    async (name, value) => {
      setErrors({});

      setData(d => ({ ...d, [name]: value }));

      const resetRenter = {
        renter_id: '',
        renter_phone: '',
        renter_email: '',
      };

      if (
        (data.renter_type === 'f' && value.length === 14) ||
        (data.renter_type === 'j' && value.length === 18)
      ) {
        const result = await api.post(`/retornaInteressado.php`, {
          doc: value,
        });

        if (result.status === 200 && result.data.result) {
          const person = result.data.data;

          let doc = {};

          if (data.renter_type === 'f') {
            doc = {
              renter_cpf: value,
            };
          } else if (data.renter_type === 'j' && !isRenter) {
            doc = {
              renter_cnpj: value,
            };
          }

          let company = {};
          if (isRenter) {
            company = {
              renter_company: person.Empresa,
              renter_function_company: person.Cargo,
              renter_contact_company: person.Contato,
              renter_phone_company: person.Fone_Empresa,
            };
          }

          setData(d => ({
            ...d,
            ...doc,
            renter_id: person.Codigo,
            renter_name: person.Nome,
            renter_phone: person.Celular,
            renter_email: person.E_mail,
            ...company,
          }));
        } else if (result.status === 200 && !result.data.result) {
          setData(d => ({
            ...d,
            ...resetRenter,
          }));
          setErrors({
            renter_cpf: 'O cpf informado não pertence a um inquilino',
            renter_cnpj: 'O cnpj informado não pertence a um inquilino',
          });
        }
      } else if (
        ((data.renter_type === 'f' && value.length < 14) ||
          (data.renter_type === 'j' && value.length < 18)) &&
        data.renter_id
      ) {
        setData(d => ({
          ...d,
          ...resetRenter,
        }));
      }
    },
    [data, setData, setErrors, isRenter]
  );

  return (
    <Container>
      <TypeClaimantControl>
        <Switch
          name="claimant_is_renter"
          checked={isRenter}
          onChange={() => handleChangeIsRenter(!isRenter)}
        />{' '}
        Usar o mesmo documento {data.claimant_cpf}
      </TypeClaimantControl>
      {!isRenter && (
        <TypePersonControl>
          <TypePerson htmlFor="t-1">
            <input
              id="t-1"
              type="radio"
              name="renter_type"
              value="f"
              checked={data.renter_type === 'f'}
              onChange={handleChangeInput}
            />
            <div>Pessoa física</div>
          </TypePerson>
          <TypePerson htmlFor="t-2">
            <input
              id="t-2"
              type="radio"
              name="renter_type"
              value="j"
              checked={data.renter_type === 'j'}
              onChange={handleChangeInput}
            />
            <div>Pessoa jurídica</div>
          </TypePerson>
        </TypePersonControl>
      )}
      {data.renter_type === 'f' && (
        <InputMask
          label="CPF"
          name="renter_cpf"
          mask="999.999.999-99"
          value={data.renter_cpf}
          error={errors.renter_cpf}
          onChange={e => handleChangeDoc(e.target.name, e.target.value)}
          disabled={isRenter ? 'disabled' : ''}
        />
      )}
      {data.renter_type === 'j' && (
        <InputMask
          label="CNPJ"
          name="renter_cnpj"
          mask="99.999.999/9999-99"
          value={data.renter_cnpj}
          error={errors.renter_cnpj}
          onChange={e => handleChangeDoc(e.target.name, e.target.value)}
        />
      )}
      <Input
        label="Nome"
        name="renter_name"
        value={data.renter_name}
        error={errors.renter_name}
        onChange={handleChangeInput}
      />
      {data.renter_id && (
        <BoxInfo>
          <Info>
            <Label>Celular: </Label>
            <Value>{data.renter_phone}</Value>
          </Info>
          <Info>
            <Label>E-mail: </Label>
            <Value>{data.renter_email}</Value>
          </Info>
          {isRenter && (
            <>
              <Info>
                <Label>Empresa: </Label>
                <Value>{data.renter_company}</Value>
              </Info>
              <Info>
                <Label>Cargo: </Label>
                <Value>{data.renter_function_company}</Value>
              </Info>
              <Info>
                <Label>Contato: </Label>
                <Value>{data.renter_contact_company}</Value>
              </Info>
              <Info>
                <Label>Telefone empresa: </Label>
                <Value>{data.renter_phone_company}</Value>
              </Info>
            </>
          )}
        </BoxInfo>
      )}
      {!data.renter_id && (
        <>
          <InputMask
            label="Celular"
            name="renter_phone"
            mask="(99) 99999-9999"
            value={data.renter_phone}
            error={errors.renter_phone}
            onChange={handleChangeInput}
          />
          <Input
            label="E-mail"
            name="renter_email"
            value={data.renter_email}
            error={errors.renter_email}
            onChange={handleChangeInput}
          />
        </>
      )}
      {!isRenter && (
        <>
          <Input
            label="Empresa"
            name="renter_company"
            value={data.renter_company}
            error={errors.renter_company}
            onChange={handleChangeInput}
          />
          <Input
            label="Cargo"
            name="renter_function_company"
            value={data.renter_function_company}
            error={errors.renter_function_company}
            onChange={handleChangeInput}
          />
          <InputMask
            name="renter_phone_company"
            label="Telefone da empresa"
            mask="(99) 9999-99999"
            value={data.renter_phone_company}
            error={errors.renter_phone_company}
            onChange={handleChangeInput}
          />
          <Input
            name="renter_contact_company"
            label="Contato"
            value={data.renter_contact_company}
            error={errors.renter_contact_company}
            onChange={handleChangeInput}
          />
        </>
      )}
    </Container>
  );
};

export default Renter;
