import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Default = ({ children }) => {
  return <Container>{children}</Container>;
};

Default.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Default;
