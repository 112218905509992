import axios from 'axios';
import https from 'https';
import { trackPromise } from 'react-promise-tracker';

const api = axios.create({
  baseURL: process.env.REACT_APP_EXTERNAL_URL,
  headers: {
    Accept: 'application/json',
    httpAgent: new https.Agent({ keepAlive: true, rejectUnauthorized: false }),
  },
});

// variável pra saber qual url fazer requisição (usada nos interceptors)
// let baseURL = process.env.REACT_APP_EXTERNAL_URL;

// consulta qual o ip de quem abriu o app
// const checkIP = async () => {
//   const result = await axios.get('https://www.cloudflare.com/cdn-cgi/trace', {
//     headers: {
//       Accept: 'application/json',
//     },
//   });

//   const ip = result.data
//     .substring(result.data.indexOf('ip=') + 3, result.data.indexOf('ts='))
//     .trim();

//   if (['191.5.125.11', '131.255.68.228'].includes(ip)) {
//     baseURL = process.env.REACT_APP_INTERNAL_URL;
//   }
// };
// checkIP(); // executa a função assim que carrega o arquivo

const get = param => trackPromise(api.get(param));

const post = (param, body) => trackPromise(api.post(param, body));

const put = (param, body) => trackPromise(api.put(param, body));

const del = param => trackPromise(api.delete(param));

// Request interceptor
api.interceptors.request.use(async config => {
  // config.baseURL = baseURL;
  config.headers.Authorization = `20D99Rx5VAAcELCE8u3epPCaR7fcZbCf`;

  return config;
});

// Convert objet to string params
// Ex: { a: 1, b: [1,2] } -> "a=1&b[]=1&b[]=2"
export const queryBuilder = params => {
  const keys = Object.keys(params);

  return `?${keys
    .map(key => {
      if (!Array.isArray(params[key])) {
        return `${key}=${params[key]}`;
      }

      return params[key].map(p => `${key}[]=${p}`).join('&');
    })
    .join('&')}`;
};

export default {
  get,
  post,
  put,
  delete: del,
};
