import yup from '~/lib/yup';

export const claimant = yup.object({
  claimant_id: yup.string().default(''),
  claimant_cpf: yup
    .string()
    .required()
    .length(14)
    .label('CPF'),
  claimant_name: yup
    .string()
    .required()
    .label('Nome'),
  claimant_phone: yup.string().when('claimant_id', {
    is: '',
    then: yup
      .string()
      .required()
      .label('Telefone'),
  }),
  claimant_email: yup.string().when('claimant_id', {
    is: '',
    then: yup
      .string()
      .trim()
      .email()
      .required()
      .label('E-mail'),
  }),
  claimant_company: yup.string().when('claimant_id', {
    is: '',
    then: yup
      .string()
      .required()
      .label('Empresa'),
  }),
  claimant_function_company: yup.string().when('claimant_id', {
    is: '',
    then: yup
      .string()
      .required()
      .label('Função'),
  }),
  claimant_contact_company: yup.string().when('claimant_id', {
    is: '',
    then: yup
      .string()
      .required()
      .label('Contato'),
  }),
  claimant_phone_company: yup.string().when('claimant_id', {
    is: '',
    then: yup
      .string()
      .required()
      .label('Telefone'),
  }),
});

export const renter = yup.object({
  renter_id: yup.string().default(''),
  renter_cpf: yup.string().when('renter_type', {
    is: 'f',
    then: yup
      .string()
      .required()
      .length(14)
      .label('CPF'),
  }),
  renter_cnpj: yup.string().when('renter_type', {
    is: 'j',
    then: yup
      .string()
      .required()
      .length(18)
      .label('CNPJ'),
  }),
  renter_name: yup
    .string()
    .required()
    .label('Nome'),
  renter_phone: yup.string().when('renter_id', {
    is: '',
    then: yup
      .string()
      .required()
      .label('Telefone'),
  }),
  renter_email: yup.string().when('renter_id', {
    is: '',
    then: yup
      .string()
      .trim()
      .email()
      .required()
      .label('E-mail'),
  }),
  renter_company: yup
    .string()
    .when('renter_id', {
      is: '',
      then: yup
        .string()
        .required()
        .label('Empresa'),
    })
    .when('claimant_is_renter', {
      is: false,
      then: yup
        .string()
        .required()
        .label('Empresa'),
    }),
  renter_function_company: yup
    .string()
    .when('renter_id', {
      is: '',
      then: yup
        .string()
        .required()
        .label('Função'),
    })
    .when('claimant_is_renter', {
      is: false,
      then: yup
        .string()
        .required()
        .label('Função'),
    }),
  renter_contact_company: yup
    .string()
    .when('renter_id', {
      is: '',
      then: yup
        .string()
        .required()
        .label('Contato'),
    })
    .when('claimant_is_renter', {
      is: false,
      then: yup
        .string()
        .required()
        .label('Contato'),
    }),
  renter_phone_company: yup
    .string()
    .when('renter_id', {
      is: '',
      then: yup
        .string()
        .required()
        .label('Telefone'),
    })
    .when('claimant_is_renter', {
      is: false,
      then: yup
        .string()
        .required()
        .label('Telefone'),
    }),
});

export const departament = yup.object({
  departament: yup
    .string()
    .required()
    .label('Departamento'),
});

export const message = yup.object({
  message: yup
    .string()
    .min(67)
    .required()
    .label('Mensagem'),
});
