import styled from 'styled-components';
import { Link } from 'react-router-dom';
import devices from '~/styles/config/device';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);

  background-color: #f5f5f5;

  @media ${devices.mobile} {
    padding: 15px;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  color: #2c2c2e;
`;

export const Protocol = styled.span`
  color: #1c1c1e;
  font-weight: 800;
`;

export const Message = styled.p`
  padding: 15px 0;
  font-size: 16px;
  color: #2c2c2e;
`;

export const Consult = styled(Link)`
  color: #007aff;
  font-weight: 500;
  text-decoration: none;
`;
