import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div``;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #2c2c2e;
`;

export const TextField = styled(InputMask)`
  font-size: 16px;
  padding: 6px 0 7px 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  transition: border-bottom 200ms linear;

  &:focus {
    border-bottom: 1px solid #1c1c1e;
  }
`;

export const Error = styled.span`
  width: 100%;
  display: inline-block;
  font-size: 12px;
  margin-top: 2px;
  color: #ff3b30;
`;
