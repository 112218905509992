import React from 'react';

import { Container, Content, Title, Info, Button, Controls } from './styles';

const Home = () => {
  return (
    <Container>
      <Content>
        <Title>Solicitações</Title>
        <Info>
          Conforme determinação da prefeitura municipal de Olimpia através do
          decreto nº 7724 publicado em 20 de março de 2020, foi proibido o
          atendimento ao publico nos estabelecimentos comerciais. Seguindo a
          situação emergencial decretada, a Imobiliária Rossi informa a todos
          que não haverá atendimento presencial na empresa, devendo nossos
          clientes se comunicarem através dos nossos telefones. Continuaremos a
          trabalhar normalmente, sem a presença de clientes nas dependências da
          empresa conforme manda o decreto. Informamos que este cenário seguirá
          enquanto estiver vigente o decreto citado, inclusive com suas
          possíveis prorrogações.
        </Info>
        <Controls>
          <Button to="/nova-solicitacao">Nova solicitação</Button>
          <Button to="/consultar-solicitacao">Consultar solicitação</Button>
        </Controls>
      </Content>
    </Container>
  );
};

export default Home;
