import styled from 'styled-components';
import { Link } from 'react-router-dom';
import devices from '~/styles/config/device';

export const Container = styled.div`
  min-height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  @media ${devices.mobile} {
    padding: 15px;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 800;
  color: #1c1c1e;
  margin-bottom: 15px;
`;

export const Info = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
  color: #3a3a3c;
`;

export const Controls = styled.div``;

export const Button = styled(Link)`
  display: inline-block;
  font-size: 16px;
  padding: 10px 15px;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 15px;

  font-weight: 500;

  &:first-of-type {
    color: #ffffff;
    background-color: #ff3b30;
    margin-right: 15px;
  }
  &:last-of-type {
    color: #ff3b30;
    border: 1px solid #ff3b30;
    background-color: transparent;
  }
`;
