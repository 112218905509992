import React, { useContext, useEffect } from 'react';
import Input from '~/components/Material/Input';
import InputMask from '~/components/Material/InputMask';
import api from '~/services/api';

import { Container, BoxInfo, Info, Label, Value } from './styles';
import FormContext from '../FormContext';

// 213.719.698-09 - Rodrigo
// 147.851.788-33 - Alessadra
const Claimant = () => {
  const { handleChangeInput, data, errors, setData } = useContext(FormContext);

  useEffect(() => {
    const resetClaimant = {
      claimant_id: '',
      claimant_phone: '',
      claimant_email: '',
      claimant_company: '',
      claimant_function_company: '',
      claimant_contact_company: '',
      claimant_phone_company: '',
    };

    const getClaimant = async () => {
      const result = await api.post(`/retornaRequerente.php`, {
        doc: data.claimant_cpf,
      });

      if (result.status === 200 && result.data.result) {
        const person = result.data.data;
        setData(d => ({
          ...d,
          claimant_id: person.Codigo,
          claimant_name: person.Nome,
          claimant_phone: person.Celular,
          claimant_email: person.Email,
          claimant_company: person.Empresa,
          claimant_function_company: person.Cargo,
          claimant_contact_company: person.Contato,
          claimant_phone_company: person.Fone_Empresa,
        }));
      } else if (result.status === 200 && !result.data.result) {
        setData(d => ({
          ...d,
          ...resetClaimant,
        }));
      }
    };

    if (data.claimant_cpf.length === 14) {
      getClaimant();
    } else if (data.claimant_id && data.claimant_cpf.length < 14) {
      setData(d => ({
        ...d,
        ...resetClaimant,
      }));
    }
  }, [data.claimant_id, data.claimant_cpf, setData]);

  return (
    <Container>
      <InputMask
        label="CPF"
        name="claimant_cpf"
        mask="999.999.999-99"
        value={data.claimant_cpf}
        error={errors.claimant_cpf}
        onChange={handleChangeInput}
      />
      <Input
        label="Nome"
        name="claimant_name"
        value={data.claimant_name}
        error={errors.claimant_name}
        onChange={handleChangeInput}
      />
      {data.claimant_id && (
        <BoxInfo>
          <Info>
            <Label>Celular: </Label>
            <Value>{data.claimant_phone}</Value>
          </Info>
          <Info>
            <Label>E-mail: </Label>
            <Value>{data.claimant_email}</Value>
          </Info>
          <Info>
            <Label>Empresa: </Label>
            <Value>{data.claimant_company}</Value>
          </Info>
          <Info>
            <Label>Cargo: </Label>
            <Value>{data.claimant_function_company}</Value>
          </Info>
          <Info>
            <Label>Contato: </Label>
            <Value>{data.claimant_contact_company}</Value>
          </Info>
          <Info>
            <Label>Telefone empresa: </Label>
            <Value>{data.claimant_phone_company}</Value>
          </Info>
        </BoxInfo>
      )}
      {!data.claimant_id && (
        <>
          <InputMask
            label="Celular"
            name="claimant_phone"
            mask="(99) 99999-9999"
            value={data.claimant_phone}
            error={errors.claimant_phone}
            onChange={handleChangeInput}
          />
          <Input
            label="E-mail"
            name="claimant_email"
            value={data.claimant_email}
            error={errors.claimant_email}
            onChange={handleChangeInput}
          />

          <Input
            label="Empresa"
            name="claimant_company"
            value={data.claimant_company}
            error={errors.claimant_company}
            onChange={handleChangeInput}
          />
          <Input
            label="Cargo"
            name="claimant_function_company"
            value={data.claimant_function_company}
            error={errors.claimant_function_company}
            onChange={handleChangeInput}
          />
          <InputMask
            name="claimant_phone_company"
            label="Telefone da empresa"
            mask="(99) 9999-99999"
            value={data.claimant_phone_company}
            error={errors.claimant_phone_company}
            onChange={handleChangeInput}
          />
          <Input
            name="claimant_contact_company"
            label="Contato"
            value={data.claimant_contact_company}
            error={errors.claimant_contact_company}
            onChange={handleChangeInput}
          />
        </>
      )}
    </Container>
  );
};

export default Claimant;
