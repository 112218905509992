import React, { useContext } from 'react';

import { Container, Content, Card, Error } from './styles';
import FormContext from '../FormContext';

// 065.526.718-22
const Departament = () => {
  const { handleChangeInput, data, errors } = useContext(FormContext);

  return (
    <Container>
      <Content>
        {errors.departament && <Error>{errors.departament}</Error>}
        <Card htmlFor="dpt-1">
          <input
            id="dpt-1"
            type="radio"
            name="departament"
            value="Financeiro"
            checked={data.departament === 'Financeiro'}
            onChange={handleChangeInput}
          />
          <div>Financeiro</div>
        </Card>
        <Card htmlFor="dpt-2">
          <input
            id="dpt-2"
            type="radio"
            name="departament"
            value="Vistoria"
            checked={data.departament === 'Vistoria'}
            onChange={handleChangeInput}
          />
          <div>Vistoria</div>
        </Card>
        <Card htmlFor="dpt-3">
          <input
            id="dpt-3"
            type="radio"
            name="departament"
            value="Locação"
            checked={data.departament === 'Locação'}
            onChange={handleChangeInput}
          />
          <div>Locação</div>
        </Card>
        <Card htmlFor="dpt-4">
          <input
            id="dpt-4"
            type="radio"
            name="departament"
            value="Manutenção"
            checked={data.departament === 'Manutenção'}
            onChange={handleChangeInput}
          />
          <div>Manutenção</div>
        </Card>
        <Card htmlFor="dpt-5">
          <input
            id="dpt-5"
            type="radio"
            name="departament"
            value="Venda"
            checked={data.departament === 'Venda'}
            onChange={handleChangeInput}
          />
          <div>Venda</div>
        </Card>
      </Content>
    </Container>
  );
};

export default Departament;
