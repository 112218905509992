import React from 'react';
import PropTypes from 'prop-types';

import { Container, TextField, Label, Error } from './styles';

const Input = ({
  type,
  label,
  name,
  value,
  onChange,
  mask,
  error,
  ...rest
}) => {
  return (
    <Container className="material-input">
      <Label>{label}</Label>
      <TextField
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        mask={mask}
        maskChar=""
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

Input.defaultProps = {
  type: 'text',
  value: '',
  error: '',
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default Input;
