import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const TypeClaimantControl = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #2c2c2e;
  border-bottom: 1px solid #eeeeee;
`;

export const TypePersonControl = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;

  label {
    &:first-of-type > input + div {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type > input + div {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const TypePerson = styled.label`
  cursor: pointer;

  > input {
    display: none;
    + div {
      font-size: 16px;
      text-align: center;
      padding: 10px 0;
      margin-bottom: 15px;
      color: #3a3a3c;
      border: 1px solid #eee;
    }
    &:checked + div {
      font-weight: 500;
      color: #007aff;
      border: 1px solid #007aff;
      background-color: ${lighten(0.4, '#007AFF')};
    }
  }
`;

export const BoxInfo = styled.div`
  padding: 15px 0;
`;

export const Info = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const Value = styled.span``;
