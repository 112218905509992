import styled from 'styled-components';
import devices from '~/styles/config/device';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
z-index: 3;

  svg {
    height: 50px;
  }

  /* @media ${devices.mobile} { */
    padding: 0 15px;
  /* } */
`;

export const Links = styled.div`
  padding-right: 15px;
  font-size: 16px;

  > a {
    color: #1c1c1e;
    font-weight: 500;
    text-decoration: none;
  }
`;
