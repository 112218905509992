import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '~/assets/logo.svg';

import { Container, Links } from './styles';

const Header = () => {
  return (
    <Container>
      <Logo />
      <Links>
        <Link to="/">Página inicial</Link>
      </Links>
    </Container>
  );
};

export default Header;
