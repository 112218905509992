import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const BoxInfo = styled.div`
  padding: 15px 0;
`;

export const Info = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const Value = styled.span``;
